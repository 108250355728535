import React from 'react'
import axios from 'axios'

export default class TrainingDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      isMobile: false
    }
  }

  componentDidMount() {
    var ql = window.matchMedia("(max-width: 1000px)");

    this.setState({isMobile: ql.matches});

    const pageFullPath = this.props.location.pathname
    const trainingUrl = pageFullPath.substring(
      pageFullPath.lastIndexOf('/') + 1
    )

    axios
      .get(`${process.env.API_URL}/umbraco/api/training/getDetail?url=${trainingUrl}`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const { data, isMobile } = this.state
    let objectives = '';
    let program = '';
    let header = '';
    let title = '';
    let photoUrl = '';
    let duration = '';
    let audience = '';
    let participants = '';
    let teachers = '';
    let date = '';
    let formUrl = '';

    if (data) {
      header = data.Type === 'short' ? 'Formação Curta' : 'Formação Longa';
      title = data.Name || "</br>";
      program = data.Program || "</br>";
      objectives = data.Objectives || "</br>";
      duration = data.Duration || "</br>";
      audience = data.Audience || "</br>";
      participants = data.Participants || "</br>";
      teachers = data.Teachers || "</br>";
      date = data.Date || "A definir";
      formUrl = data.FormUrl;
      photoUrl = `${process.env.API_URL}${data.Photo}`
    }

    const descriptionImage = (
       <div className="about-slide">
         <img alt="Formação" src={photoUrl} />
      </div>);

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>{header}</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-12 col-xs-12">
                <div className="about_content">
                  <h1>{title.replace(/&nbsp;/g, '')}</h1>
                  {
                    isMobile ? descriptionImage : null
                  }
                  <h4 className="sub-header">Objetivos</h4>
                  <div dangerouslySetInnerHTML={{ __html: objectives }} />
                  <h4 className="sub-header mt-s">Programa</h4>
                  <div dangerouslySetInnerHTML={{ __html: program }} />
                  <h4 className="sub-header mt-s">Dinamizadores</h4>
                  <div dangerouslySetInnerHTML={{ __html: teachers }} />
                </div>
              </div>
              <div className="col-md-5 col-sm-12 col-xs-12">
                {
                  isMobile ? null : descriptionImage
                }
                <div>
                  <h4 className="sub-header mt-s">Data de Início</h4>
                  <div dangerouslySetInnerHTML={{ __html: date }} />
                  <h4 className="sub-header mt-s">Duração</h4>
                  <div dangerouslySetInnerHTML={{ __html: duration }} />
                  <h4 className="sub-header mt-s">Destinatários</h4>
                  <div dangerouslySetInnerHTML={{ __html: audience }} />
                  <h4 className="sub-header mt-s">Número de Participantes</h4>
                  <div dangerouslySetInnerHTML={{ __html: participants }} />
                </div>
              </div>
              {
                formUrl ? <div className="col-xs-12">
                  <a className="btn-contact-bg solid xl mt-l" rel="noopener noreferrer" target="_blank" href={formUrl}>
                    <span>Inscreva-se</span>
                  </a>
                </div> : null
              }
            </div>
          </div>
        </section>
      </div>
    )
  }
}
